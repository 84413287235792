<template>
  <a-form-model
    ref="form"
    :rules="rules"
    :label-col="{ span: 6 }"
    :wrapper-col="{ span: 15 }"
    :model="form"
  >
    <a-form-model-item label="团队全称:" prop="fullName">
      <a-input v-model="form.fullName" type="string" placeholder="请输入" />
    </a-form-model-item>
    <a-form-model-item label="团队简称:" prop="name">
      <a-input v-model="form.name" type="string" placeholder="请输入" />
    </a-form-model-item>

    <a-form-model-item label="业务编号:" prop="code">
      <a-input v-model="form.code" type="string" placeholder="请输入小于4位数字" />
    </a-form-model-item>

    <a-form-model-item label="团队类型" prop="orgTypeCode">
      <a-select
        disabled
        v-model="form.orgTypeCode"
        placeholder="请选择"
      >
        <a-select-option
          :value="item.value"
          v-for="(item, index) in orgTypeList"
          :key="index"
        >
          {{ item.name }}
        </a-select-option>
      </a-select>
    </a-form-model-item>

    <a-form-model-item label="LOGO:" prop="logo">
        <ImageManage v-model="form.logo" @change="$refs.form.validateField(['logo'])" :square="false" :size="1024*1024*2" :showWidth="true">
            <div slot="extra">建议图片180*60，建议大小在50KB以内，仅限JPG、PNG</div>
        </ImageManage>
      <!-- <a-input v-model="form.logo" placeholder="请输入" style="display: none" />
      <div style="display: flex">
        <a-upload
          v-if="!disabledStatus"
          name="file"
          list-type="picture-card"
          class="avatar-uploader avatar-uploader1"
          :show-upload-list="false"
          :action="$consts.uploadFileUrl"
          :headers="$store.state.header"
          :before-upload="
            (e) => {
              beforeUpload(e);
            }
          "
          @change="
            (e) => {
              handleChange(e);
            }
          "
        >
          <a-avatar
            shape="square"
            style="width: 80px; height: 80px"
            v-if="form.logo"
            :src="form.logo"
          />
          <div v-else>
            <a-icon :type="loading1 ? 'loading' : 'plus'" />
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
        <a-avatar
          shape="square"
          v-else
          style="width: 102px; height: 102px"
          :src="form.logo"
        />
      </div>
      <div slot="extra">建议图片180*60，建议大小在50KB以内，仅限JPG、PNG</div> -->
    </a-form-model-item>

    <a-form-model-item label="暗号:" prop="countersign">
      <a-input v-model="form.countersign" type="string" placeholder="请输入" />
    </a-form-model-item>
    <a-form-model-item label="管理员手机号:" prop="managePhone">
      <a-input v-model="form.managePhone" type="string" placeholder="请输入" />
    </a-form-model-item>
    <a-form-model-item label="管理员姓名:" prop="manageName">
      <a-input v-model="form.manageName" type="string" placeholder="请输入" />
    </a-form-model-item>
    <a-form-model-item label="联系人手机号:" prop="phone">
      <a-input v-model="form.phone" type="string" placeholder="请输入" />
    </a-form-model-item>
    <a-form-model-item label="联系人姓名:" prop="contacts">
      <a-input v-model="form.contacts" type="string" placeholder="请输入" />
    </a-form-model-item>
    <a-form-model-item label="联系人邮箱:" prop="email">
      <a-input v-model="form.email" type="string" placeholder="请输入" />
    </a-form-model-item>

    <a-form-model-item
      :colon="false"
      label=" "
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 8 }"
    >
      <a-space>
        <a-button @click="cancel"> 取消 </a-button>
        <a-button @click="determine" type="primary">
          {{ form.id ? "编辑" : "添加" }}</a-button
        >
      </a-space>
    </a-form-model-item>

    <br />
  </a-form-model>
</template>

<script>
import ImageManage from '@/components/imageManage/index.vue'
export default {
  components: { ImageManage },
  data () {
    return {
      disabledStatus: false,
      loading1: false,
      btCount: 0,
      overSize: true,
      uploadStatus: true,
      zanshiFile: '',
      orgTypeList: [
        {
          value: 'team',
          name: '团队'
        }
      ], // 团队列表
      isDetermineLoading: false, // 是否显示确定loading
      form: {},
      rules: {
        grade: undefined,
        name: [
          {
            required: true,
            message: '姓名不能为空',
            trigger: 'change'
          }
        ],
        fullName: [
          {
            required: true,
            message: '全称不能为空',
            trigger: 'change'
          }
        ],
        code: [
          {
            required: true,
            message: '请输入小于4位的数字',
            trigger: 'change'
          },
          {
            pattern: /^(0|([0-9]\d{0,2}))$/,
            message: '请输入小于4位的数字'
          }
        ],
        logo: [
          {
            required: true,
            message: '请上传团队LOGO',
            trigger: 'change'
          }
        ],
        managePhone: [
          {
            required: true,
            message: '请填写管理员手机号',
            trigger: 'change'
          },
          {
            pattern: this.$consts.phoneReg,
            message: '请输入正确的手机号'
          }
        ],
        manageName: [
          {
            required: true,
            message: '请填写管理员姓名',
            trigger: 'change'
          }
        ],
        phone: [
          {
            required: true,
            message: '请填写联系人手机号',
            trigger: 'change'
          },
          {
            pattern: this.$consts.phoneReg,
            message: '请输入正确的手机号'
          }
        ],
        contacts: [
          {
            required: true,
            message: '请填写联系人姓名',
            trigger: 'change'
          }
        ],
        email: [
          {
            required: true,
            message: '请输入邮箱',
            trigger: 'change'
          },
          {
            pattern: this.$consts.emailReg,
            message: '请输入正确的邮箱号'
          }
        ],
        countersign: [
          {
            required: true,
            message: '请输入暗号',
            trigger: 'change'
          }
        ]
      }
    }
  },
  created () {},
  mounted () {},
  computed: {},
  methods: {
    resetForm () {
      this.form = {
        id: '',
        name: '',
        fullName: '',
        orgTypeCode: 'team',
        logo: '',
        managePhone: '',
        manageName: '',
        phone: '',
        contacts: '',
        email: '',
        countersign: '',
        grade: undefined
      }
    },
    cancel () {
      console.log(1111)
      this.$refs.form.resetFields()
      this.$refs.form.clearValidate()
      this.form = {
        name: '',
        channelId: '',
        phone: '',
        invitePhone: ''
      }
      this.$emit('cancel')
    },
    beforeUpload (file) {
      this.zanshiFile = file
      this.overSize = false
      let isOverSize
      isOverSize = file.size / 1024 < 50
      const isJpgOrPng =
        file.type === 'image/jpeg' || file.type === 'image/png'
      setTimeout(() => {
        if (!isJpgOrPng) {
          this.$store.dispatch('showToast', {
            type: 'warning',
            message: '图片格式不正确'
          })
          return false
        }
        // if (this.uploadStatus) {
        //   this.$store.dispatch('showToast', { type: 'error', message: '请上传图片宽高比率为1:1的像素图片' })
        //   return false
        // }
        if (!isOverSize) {
          this.$store.dispatch('showToast', {
            type: 'error',
            message: '图片大小不能超过50KB'
          })
          this.overSize = true
        }
      }, 200)
      return isJpgOrPng && isOverSize
    },
    async handleChange (info) {
      if (info.file.status === 'uploading') {
        this.loading = true
        return
      }
      if (info.file.status === 'done') {
        this.getIsBiLi(this.zanshiFile)
        setTimeout(() => {
          if (!this.overSize && !this.uploadStatus) {
            this.form.logo = info.file.response.result
          }
        }, 300)
        this.loading = false
      }
    },
    getIsBiLi (file) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        const image = new Image()
        image.src = reader.result
        image.onload = () => {
          this.uploadStatus = false
          //   const w = image.width
          //   const h = image.height
          //   if (w / h !== 1) {
          //     this.uploadStatus = true
          //   } else {
          //     this.uploadStatus = false
          //   }
        }
      }
    },
    async init (
      item = {
        id: '',
        name: '',
        fullName: '',
        orgTypeCode: 'team',
        logo: '',
        managePhone: '',
        manageName: '',
        phone: '',
        contacts: '',
        email: '',
        countersign: '',
        grade: undefined
      }
    ) {
      const {
        id,
        name,
        fullName,
        orgTypeCode,
        logo,
        managePhone,
        manageName,
        phone,
        contacts,
        email,
        countersign,
        grade
      } = item
      this.form = {
        id,
        name,
        fullName,
        orgTypeCode,
        logo,
        managePhone,
        manageName,
        phone,
        contacts,
        email,
        countersign,
        grade
      }
    },
    // 提交保存
    async determine (e) {
      if (!this.btCount) {
        this.goComfirm()
        this.btCount++
      }
      setTimeout(() => {
        this.btCount = 0
      }, 1000)
    },
    async goComfirm (e) {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          const params = this.$utils.clone(this.form)
          let api = 'addTeam'
          if (params.id) {
            api = 'editTeam'
          }
          await this.$store.dispatch('http', {
            api,
            params,
            complete: () => {
              this.isDetermineLoading = false
            }
          })
          this.$store.dispatch('showToast', {
            message: (api === 'editTeam' ? '编辑' : '新增') + '团队成功'
          })
          this.$emit('ok')
        }
      })
    }
  }
}
</script>

<style lang="scss" module>
.container {
  width: auto;
}
</style>
