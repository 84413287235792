<template>
    <div :class="$style.container">
    <!-- 面包屑导航 -->
    <Breadcrumb :breadList="breadList">
        <a-button class="add-bt" type="primary"  @click="add()">新增</a-button>
    </Breadcrumb>
    <a-form ref="form" class="al-form" layout="inline" :model="form" :label-col="{ style: 'width: 80px' }" :wrapper-col="{ style: 'width: 170px' }">
      <a-form-item label="手机号:">
        <a-input v-model="form.phone" placeholder="请输入手机号" />
      </a-form-item>

      <a-row class="al-form__btn"
             type="flex"
             justify="end">
        <a-button type="primary"
                  :loading="isQueryLoading"
                  @click="queryList">查询</a-button>
        <a-button @click="resetForm('form')"
                  style="margin-left:20px;">重置</a-button>
      </a-row>
    </a-form>

    <a-table :data-source="list"
             bordered
             :rowKey="record => record.id"
             @change="currentChange"
             :columns="columns"
             :pagination="pagination">
         <div slot="fullName" slot-scope="text,record">
             <img :src="record.logo" style="max-height:20px;" />
             {{record.fullName}}
        </div>
         <div slot="manageName" slot-scope="text,record">
             {{record.manageName}}
        </div>
         <div slot="contacts" slot-scope="text,record">
             {{record.contacts}}
        </div>
         <div slot="action" slot-scope="text,record">
             <a-space>
                <a @click="edit(record)" ><a-spin size="small" v-if="isLoading" />编辑</a>
                <a @click="award(record)" ><a-spin size="small" v-if="isLoading" />团队奖励</a>
                <a @click="distributorAward(record)" ><a-spin size="small" v-if="isLoading" />分销员奖励</a>
             </a-space>
        </div>
    </a-table>

    <!-- 编辑团队弹窗 -->
    <a-modal
        :title="modalTitle"
      :footer="null"
      :visible="visible"
      @ok="handleOk"
       @cancel="handleCancel"
    >
        <EditTeam  @ok="handleOk" @cancel="handleCancel" ref="modalRef"></EditTeam>
    </a-modal>

    <!-- 编辑award 奖励弹窗 -->
    <a-modal
        :title="awardModalTitle"
      :footer="null"
      :visible="awardVisible"
      @ok="handleOk"
       @cancel="handleCancel"
    >
        <EditAward  @ok="handleOk" @cancel="handleCancel" ref="awardModalRef"></EditAward>
    </a-modal>

    <!-- 编辑 分销员 奖励弹窗 -->
    <a-modal
        :title="distributorAwardModalTitle"
      :footer="null"
      :visible="distributorAwardVisible"
      @ok="handleOk"
       @cancel="handleCancel"
    >
        <EditDistributorAward  @ok="handleOk" @cancel="handleCancel" ref="distributorAwardModalRef"></EditDistributorAward>
    </a-modal>
  </div>
</template>

<script>
import Breadcrumb from '@/components/breadcrumb.vue'
import EditTeam from './addEditTeam.vue'
import EditAward from './editAward.vue'
import EditDistributorAward from './editDistributorAward.vue'
export default {
  components: { Breadcrumb, EditTeam, EditAward, EditDistributorAward },
  data () {
    return {
      breadList: [
        {
          name: '团队管理',
          url: ''
        },
        {
          name: '团队列表',
          url: ''
        }
      ],
      modalTitle: '添加团队',
      awardModalTitle: '奖励设置',
      distributorAwardModalTitle: '分销员奖励设置',
      isLoading: false, // 编辑状态
      visible: false, // modal
      awardVisible: false, // 奖励弹窗
      distributorAwardVisible: false, // 分销员奖励弹窗
      isQueryLoading: false,
      form: {
        phone: ''
      },
      columns: [
        { title: '团队', width: 200, key: 'fullName', dataIndex: 'fullName', scopedSlots: { customRender: 'fullName' } },
        { title: '团队类型', width: 100, key: 'orgTypeCode', dataIndex: 'orgTypeCode' },
        { title: '暗号', width: 120, key: 'countersign', dataIndex: 'countersign' },
        { title: '团长/管理员', width: 150, key: 'manageName', dataIndex: 'manageName', scopedSlots: { customRender: 'manageName' } },
        { title: '联系信息', width: 100, key: 'contacts', dataIndex: 'contacts', scopedSlots: { customRender: 'contacts' } },
        { title: '团队销售额', width: 120, key: 'orderAmount', dataIndex: 'orderAmount' },
        { title: '团队订单', width: 100, key: 'orderCount', dataIndex: 'orderCount' },
        { title: '团队成员数', width: 120, key: 'frontUserCount', dataIndex: 'frontUserCount' },
        { title: '成立时间', key: 'gmtCreate', dataIndex: 'gmtCreate' },
        { title: '操作', width: 250, fixed: 'right', key: 'action', dataIndex: 'action', scopedSlots: { customRender: 'action' } }
      ],
      pagination: {
        total: 0,
        current: 1,
        pageSize: this.$enums.Pagination.pageSize,
        showLessItems: false,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: this.$enums.Pagination.pageSizes,
        showTotal: (total, range) => `共${total}条记录,第${this.pagination.current}页`
      },
      total: 0,
      list: []
    }
  },
  created () {
    this.reloadList()
  },
  mounted () {
  },
  destroyed () {
  },
  computed: {
  },
  methods: {
    //   编辑奖励
    award (record) {
      this.awardModalTitle = '团队奖励设置'
      this.awardVisible = true
      this.$nextTick(() => {
        this.$refs.awardModalRef.init(record)
      })
    },
    //   分销员奖励
    distributorAward (record) {
      this.distributorAwardModalTitle = '分销员奖励设置'
      this.distributorAwardVisible = true
      this.$nextTick(() => {
        this.$refs.distributorAwardModalRef.init(record)
      })
    },
    add () {
      this.modalTitle = '添加团队'
      this.visible = true
      this.$nextTick(() => {
        this.$refs.modalRef.init()
      })
    },
    edit (record) {
      this.modalTitle = '编辑团队'
      this.visible = true
      this.$nextTick(() => {
        this.$refs.modalRef.init(record)
      })
    },
    handleOk (e) {
      setTimeout(() => {
        this.visible = false
        this.awardVisible = false
        this.distributorAwardVisible = false
      })
      this.queryList()
    },
    handleCancel (e) {
      try {
        this.$refs.modalRef.$refs.form.clearValidate()
        this.$refs.awardModalRef.$refs.form.clearValidate()
      } catch (error) {
        console.log('refs form还未加载')
      }
      this.visible = false
      this.awardVisible = false
      this.distributorAwardVisible = false
    },
    // 重置表单
    resetForm () {
      this.form = {
        name: ''
      }
      this.queryList()
    },
    // 查询列表
    queryList () {
      this.isQueryLoading = true
      this.reloadList(() => {
        this.isQueryLoading = false
      })
    },
    // 重新加载列表（参数初始化）
    reloadList (callback = () => { }) {
      this.pagination.current = 1
      this.loadList(callback)
    },
    // 加载列表
    async loadList (callback = () => { }) {
      this.isTableLoading = true
      const params = Object.assign({
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize
      }, this.form)
      const { total, records } = await this.$store.dispatch('http', {
        api: 'teamList',
        params,
        complete: () => {
          this.isTableLoading = false
          callback()
        }
      })
      this.pagination.total = total
      this.list = records
    },
    // 分页、排序、筛选变化时触发
    currentChange (val) {
      console.log('分页、排序、筛选变化时触发', val)
      this.pagination.pageSize = val.pageSize
      this.pagination.current = val.current
      this.loadList()
    }
  }
}
</script>

<style lang="scss" module>
.container {
  width: auto;
}
</style>
