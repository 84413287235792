<template>
  <a-form-model
    ref="form"
    :rules="rules"
    :label-col="{ span: 6 }"
    :wrapper-col="{ span: 15 }"
    :model="form"
  >
    <a-form-model-item label="销售奖励:" prop="saleRadio">
      <a-input v-model="form.saleRadio" type="string" placeholder="请输入" suffix="%" />
    </a-form-model-item>

    <a-form-model-item label="团队奖励:" prop="manageRadio">
      <a-input v-model="form.manageRadio" type="string" placeholder="请输入" suffix="%" />
    </a-form-model-item>

    <a-form-model-item
      :colon="false"
      label=" "
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 8 }"
    >
      <a-space>
        <a-button @click="cancel"> 取消 </a-button>
        <a-button @click="determine" type="primary">
          {{ form.userId ? "编辑" : "确定" }}</a-button
        >
      </a-space>
    </a-form-model-item>

    <br />
  </a-form-model>
</template>

<script>
export default {
  data () {
    return {
      btCount: 0,
      isDetermineLoading: false, // 是否显示确定loading
      channelId: '',
      form: {
        saleRadio: '',
        manageRadio: ''
      },
      rules: {
        saleRadio: [
          {
            required: true,
            message: '不能为空',
            trigger: 'change'
          },
          {
            pattern: /^(\d{0,2})(\.\d{0,2})?$/,
            message: '请输入正确的百分比，保留两位小数'
          }
        ],
        manageRadio: [
          {
            required: true,
            message: '不能为空',
            trigger: 'change'
          },
          {
            pattern: /^(\d{0,2})(\.\d{0,2})?$/,
            message: '请输入正确的百分比，保留两位小数'
          }
        ]
      }
    }
  },
  created () {},
  mounted () {},
  computed: {},
  methods: {
    resetForm () {
      this.form = {
        saleRadio: '',
        manageRadio: ''
      }
    },
    cancel () {
      this.$refs.form.resetFields()
      this.$refs.form.clearValidate()
      this.resetForm()
      this.$emit('cancel')
    },
    async init (
      item = {
        id: ''
      }
    ) {
      this.channelId = item.id
      // 查找团队列表
      const res = await this.$store.dispatch('http', {
        api: 'getTeamAward',
        query: {
          channelId: item.id
        }
      })
      if (!res) {
        this.resetForm()
      }

      if (res) {
        console.log('这里需要显示原来的值吗？？？？')
        this.form.saleRadio = (res.saleRadio * 100).toFixed(2)
        this.form.manageRadio = (res.manageRadio * 100).toFixed(2)
      }
      console.log(res)
    },
    // 提交保存
    async determine (e) {
      if (!this.btCount) {
        this.goComfirm()
        this.btCount++
      }
      setTimeout(() => {
        this.btCount = 0
      }, 1000)
    },
    async goComfirm (e) {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          const params = this.$utils.clone(this.form)
          let api = 'addEditTeamAward'
          await this.$store.dispatch('http', {
            api,
            query: { channelId: this.channelId },
            params: {
              saleRadio: (params.saleRadio / 100).toFixed(4),
              manageRadio: (params.manageRadio / 100).toFixed(4)
            },
            complete: () => {
              this.isDetermineLoading = false
            }
          })
          this.$store.dispatch('showToast', {
            message: '奖励设置成功'
          })
          this.$emit('ok')
        }
      })
    }
  }
}
</script>

<style lang="scss" module>
.container {
  width: auto;
}
</style>
